<template>
  <div class="m3-flex-col">
    <div class="m3-flex m3-justify-content-center m3-padding-s md-primary-container">线路质检记录</div>
    <div class="m3-flex-col m3-padding-s">
      <!--      <div class="">机构名称：{{ detail.nickName || detail.company }}</div>-->
      <div class="m3-flex m3-margin-top-s">
        <span class="m3-flex-1 m3-text-end">服务商：</span>
        <span class="m3-flex-3"> {{ detail.amountName }}</span>
      </div>
      <div class="m3-flex m3-margin-top-s">
        <span class="m3-flex-1 m3-text-end">主叫号码：</span>
        <span class="m3-flex-3"> {{ telADesc }}</span>
      </div>
      <div class="m3-flex m3-margin-top-s">
        <span class="m3-flex-1 m3-text-end">小号号码：</span>
        <span class="m3-flex-3"> {{ telXDesc }}</span>
      </div>
      <div class="m3-flex m3-margin-top-s">
        <span class="m3-flex-1 m3-text-end">被叫号码：</span>
        <span class="m3-flex-3"> {{ telBDesc }}</span>
      </div>
      <div class="m3-flex m3-margin-top-s">
        <span class="m3-flex-1 m3-text-end">通话时长：</span>
        <span class="m3-flex-3"> {{ recordDurationDesc }}</span>
      </div>
      <div class="m3-flex m3-margin-top-s">
        <span class="m3-flex-1 m3-text-end">通话时间：</span>
        <span class="m3-flex-3"> {{ recordTimeDesc }}</span>
      </div>
      <div class="m3-flex m3-margin-top-s">
        <span class="m3-flex-1 m3-text-end">报备行业：</span>
        <span class="m3-flex-3"> {{ detail.industry }}</span>
      </div>
      <div class="m3-flex m3-margin-top-s">
        <span class="m3-flex-1 m3-text-end">违规等级：</span>
        <span class="m3-flex-3">
          <span class="m3-padding-horizontal-s m3-padding-vertical-xxs"
                :class="riskTextClass">{{ riskText }}</span>
        </span>
      </div>
      <div class="m3-flex m3-margin-top-s">
        <span class="m3-flex-1 m3-text-end">命中类型：</span>
        <span class="m3-flex-3"> {{ detail.hitType }}</span>
      </div>
      <div class="m3-flex m3-margin-top-s m3-align-items-start">
        <span class="m3-flex-1 m3-text-end">命中关键字：</span>
        <span class="m3-flex-3 m3-flex m3-flex-wrap">
         <a-tag class="m3-margin-right-s m3-margin-bottom-xs" :color="detail.auditStatus==2?'error':'default'"
                v-for="item in detail.hitTypeDescArr" :key="item">
              {{ item }}
            </a-tag>
        </span>
      </div>
      <div class="m3-flex m3-margin-top-s">
        <span class="m3-flex-1 m3-text-end">报备行业：</span>
        <span class="m3-flex-3"> {{ detail.industry }}</span>
      </div>
      <div class="m3-flex m3-margin-top-s">
        <audio class="m3-width-full" id="audio" :src="detail.recordUrl" controls="controls"></audio>
      </div>

      <div class="m3-flex-col md-surface m3-border-radius-s m3-padding-l m3-margin-vertical-s">
        <div v-for="(translatedContent,translatedContentIndex) in translatedContentList"
             :key="'translatedContent'+translatedContentIndex"
             class="m3-flex-col m3-margin-bottom-xs">
          <div v-if="isTelA(translatedContent)"
               class="m3-flex m3-justify-content-start">
            <a-avatar :size="40" :src="require(`@/assets/images/telA.png`)"></a-avatar>
            <div class="triangle-telA"></div>
            <div class="md-surface m3-padding-s m3-border-radius-xs"
                 :style="{maxWidth: '60%',backgroundColor:'#ffffff'}"
                 @click="positionAudio(translatedContent)"
                 v-html="$filter.mateKeyword(translatedContent?.text,detail.hitTypeDescArr)"></div>
          </div>
          <div v-else
               class="m3-flex m3-justify-content-end">
            <div class="md-primary-container m3-padding-s m3-border-radius-xs"
                 :style="{maxWidth: '60%',backgroundColor:'#9eea6a'}"
                 @click="positionAudio(translatedContent)"
                 v-html="$filter.mateKeyword(translatedContent?.text,detail.hitTypeDescArr)"></div>
            <div class="triangle-telB"></div>
            <a-avatar :size="40" :src="require(`@/assets/images/telB.png`)"></a-avatar>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {onMounted, onUnmounted, reactive, ref, toRefs, watch} from "vue";
import {safePromise} from "@/utils/common/safePromise";
import {lineCheckDetail, vosCheckDetail} from "@/api/open/voiceCheck/voiceCheck";
import EmptyUtil from "@/utils/common/EmptyUtil";
import industryRequest from "@/api/settings/industry";
import amount from "@/api/open/amount/amount";

export default {
  name: "LineRecord",
  props: {
    recordId: null,
  },
  setup(props, context) {
    let {recordId} = toRefs(props);

    const route = useRoute();
    let id = ref('');
    let detail = reactive({});
    let translatedContentList = ref([]);

    let getIndustryDetail = async () => {
      if (detail.businessType && EmptyUtil.isEmpty(detail.industry)) {//行业id
        //根据行业id查找行业话术
        let res = await industryRequest.queryById({id: detail.businessType});
        detail.industry = res?.data?.name || detail.industry;
        detail.template = res?.data?.template || detail.template;
      }
    }

    let getAmountDetail = async () => {
      if (detail.amountId && EmptyUtil.isEmpty(detail.amountName)) {//服务商（线路）id
        let res = await amount.amountGet({id: detail.amountId});
        detail.amountName = res?.data?.name || detail.amountName;
      }
    }
    let getDetail = async () => {
      let res = await safePromise(lineCheckDetail({id: id.value}));
      if (res.code == 200 && res.data) {
        // ObjectUtil.assign(detail, res.data);
        Object.assign(detail, res.data);
        // detail.risk = 1;
        // detail.hitTypeDesc = '天安门,官网,北京,房子,投资,傻';
        detail.hitTypeDescArr = detail.hitTypeDesc?.split(',') || [];
        let contentJson = JSON.parse(detail?.translatedContent);
        translatedContentList.value = contentJson?.payload?.sentences || [];
        // console.log('getDetail', detail, res.data);
        await getIndustryDetail();
        await getAmountDetail();
      }
    }

    let riskList = [
      // {label: '全部', value: '', desc: '0/0', labelColor: '#fff0f0'},
      {label: '特大违规', value: 1, labelClass: 'risk-high-more'},
      {label: '重大违规', value: 2, labelClass: 'risk-high'},
      {label: '一般违规', value: 3, labelClass: 'risk-mid'},
      {label: '轻微违规', value: 4, labelClass: 'risk-low'},
      // {label: '无违规', value: 0,},
    ];

    let audioTimeOut;
    //定位当前语音位置并播放
    let positionAudio = (sentence) => {
      // play()开始播放音频
      // pause()暂停当前播放的音频
      // load()重新加载音频元素
      // addTextTrack()在音频中添加一个新的文本轨道
      // fastSeek()在音频播放器中指定播放时间。
      // canPlayType()检查浏览器是否可以播放指定的音频类型
      // getStartDate()返回一个新的Date对象，表示当前时间轴偏移量
      console.log('positionAudio', sentence)
      let audio = document.getElementById("audio");
      if (null != audio && sentence.begin_time) {
        audio.currentTime = sentence.begin_time / 1000;//设置从哪里开始播放
        audio.play();
        let duration = sentence.end_time - sentence.begin_time;//结束时间减去开始时间就是时长，结合setTimeout实现只播放当前一段话
        audioTimeOut = setTimeout(() => {
          if (null != audio) {
            audio.pause();
          }
        }, duration);
      }
    }

    watch(recordId, (newV, preV) => {
      if (newV) {
        id.value = newV;
        getDetail()
      }
    }, {immediate: true})

    onUnmounted(() => {
      if (audioTimeOut) {
        clearTimeout(audioTimeOut);
      }
    })
    return {
      detail, translatedContentList,
      positionAudio, riskList,
    }
  },
  computed: {
    telADesc() {
      let res = '';
      if (this.detail) {
        if (!EmptyUtil.isEmpty(this.detail.telA)) {
          res += this.detail.telA;
        }
      }
      // console.log('telADesc', this.detail, res);
      return res;
    },
    telXDesc() {
      let res = '';
      if (this.detail) {
        if (!EmptyUtil.isEmpty(this.detail.telX)) {
          res += this.detail.telX;
        }
      }
      // console.log('telADesc', this.detail, res);
      return res;
    },
    telBDesc() {
      let res = '';
      if (this.detail) {
        if (!EmptyUtil.isEmpty(this.detail.telB)) {
          res += this.detail.telB;
        }
      }
      // console.log('telADesc', this.detail, res);
      return res;
    },
    recordDurationDesc() {
      let res = '';
      if (this.detail) {
        if (!EmptyUtil.isEmpty(this.detail.duration)) {
          res = this.detail.duration + '秒';
        }
      }
      return res;
    },
    recordTimeDesc() {
      let res = '';
      res = this.detail?.stratTime || '';
      return res;
    },
    isTelA() {
      return (translatedContent) => {
        return translatedContent?.channel_id == 1;
      }
    },
    auditStatusDesc() {
      if (this.detail?.auditStatus == '1') {
        return '正常'
      } else if (this.detail?.auditStatus == '2') {
        return '违规'
      } else if (this.detail?.auditStatus == '3') {
        return '误判'
      } else if (this.detail?.auditStatus == '4') {
        return '复审'
      } else if (this.detail?.auditStatus == '0') {
        return '未审核'
      }
      return '未知'
    },
    riskText() {
      let res = '';
      let find = this.riskList.find(risk => risk.value == this.detail?.risk);
      res = find?.label || '';
      return res;
    },
    riskTextClass() {
      let res = '';
      let find = this.riskList.find(risk => risk.value == this.detail?.risk);
      res = find?.labelClass || '';
      return res;
    },
  },
}
</script>


<style lang="scss" scoped>

.triangle-telA {
  position: relative;
  left: 2px;
  //top: 39%;
  //left: 44px;
  transform: rotate(-90deg);
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #ffffff transparent;
  width: 0px;
  height: 0px;
}

.triangle-telB {
  position: relative;
  left: -2px;
  transform: rotate(90deg);
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #9eea6a transparent;
  width: 0px;
  height: 0px;
}
</style>